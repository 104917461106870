import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Http, Headers, Request, RequestOptions, RequestMethod, Response } from '@angular/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl = environment.apiUrl;
  constructor(private http: Http,
  private auth: AuthService) { }

  get(url: string) {
    return this.request(url, RequestMethod.Get);
  }

  post(url: string, body: Object): Observable<Response> {
    return this.request(url, RequestMethod.Post, body);
  }

  put(url: string, body: Object) {
    return this.request(url, RequestMethod.Put, body);
  }

  delete(url: string) {
    return this.request(url, RequestMethod.Delete);
  }

  request(url: string, method: RequestMethod, body?: Object): Observable<Response> {
    const headers = new Headers();
     if (this.auth.getToken() !== null && this.auth.getToken() !== '')  {
    headers.append('Authorization', 'Bearer ' + this.auth.getToken());
     }
    const requestOptions = new RequestOptions({
      url: `${this.baseUrl}/${url}`,
      method: method,
      headers: headers
    });
    if (body) {
      requestOptions.body = body;
    }

    const request = new Request(requestOptions);
    return this.http.request(request);
  }
  onRequestError(res: Response) {
    const statusCode = res.status;
    const body = res.json();

    const error = {
      statusCode: statusCode,
      error: body.error
    };
    return observableThrowError(error);
  }

  multiPartRequest(url: string, body?: FormData): Observable<Response> {
    const headers = new Headers();
     if (this.auth.getToken() !== null && this.auth.getToken() !== '')  {
      headers.append('Authorization', 'Bearer ' + this.auth.getToken());
     }
    const requestOptions = new RequestOptions({
      url: `${this.baseUrl}/${url}`,
      method: 'post',
      headers: headers
    });
    if (body) {
      requestOptions.body = body;
    }

    // const request = new Request(requestOptions);
    return this.http.post(url, body, requestOptions);
  }
}
