import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';



import { SocketService } from '../../shared/socket.service';
import { ApiService } from '../api.service';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})

export class SearchComponent implements OnInit, OnChanges, OnDestroy {
  @Input() endpoint: string;
  @Input() sort: any;
  @Input() index: number;
  @Input() size: number;
  public search: string;
  private refreshQueue = new Subject();
  private refreshSubscription;

  constructor(
    private readonly api: ApiService,
    private readonly socketService: SocketService
  ) { }

  ngOnInit() {
    this.searchTerm();

    this.refreshSubscription = this.refreshQueue.pipe(debounceTime(200)).subscribe(this.searchTerm.bind(this));
  }

  ngOnChanges(changes: SimpleChanges) {
    this.refreshQueue.next();
  }

  refresh() {
    this.refreshQueue.next();
  }

  public searchTerm() {
    this.socketService.sendEvent(this.endpoint, {
      search: this.search,
      sort: this.sort,
      page: {
        currentPage: this.index,
        noofRecords: this.size,
      },
    });
  }

  public setSearchTerm (value) {
    this.search = value;
    this.searchTerm();
  }

  ngOnDestroy() {
    this.refreshSubscription.unsubscribe();
  }
}
