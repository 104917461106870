import { NgModule } from '@angular/core';
import { DemoMaterialModule } from '../demo-material-module';
import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { LoginComponent } from './login/login.component';
import { RouterModule } from '@angular/router';
import { SharedRoutes } from './shared.routes';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AuthService } from './auth/auth.service';
import { AuthGuard } from './auth/auth.guard';
import { NgxHmCarouselModule } from 'ngx-hm-carousel';
import { SliderItems } from './login/slider.content';
import { PaginationConfig } from './pagination/pagination-config';
import { SearchComponent } from './search/search.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    LoginComponent,
    SearchComponent,
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SearchComponent,
  ],
  imports: [
    DemoMaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxHmCarouselModule,
    CommonModule,
    RouterModule.forChild(SharedRoutes),
  ],
  providers: [MenuItems, AuthGuard, AuthService, SliderItems, PaginationConfig]
})
export class SharedModule { }
