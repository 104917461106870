import { Injectable } from '@angular/core';
export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  // children: Menu[];
}

const MENUITEMS = [

  { state: 'assets', name: 'Assets', type: 'link', icon: 'settings_remote' },
  { state: 'fleets', name: 'Fleets', type: 'link', icon: 'account_balance' },
  { state: 'people', name: 'People', type: 'link', icon: 'person' },
  { state: 'groups', name: 'Groups', type: 'link', icon: 'group' },
  { state: 'gps', name: 'IoT Reports', type: 'link', icon: 'gps_fixed' },
  { state: 'analytics', name: 'Analytics', type: 'link', icon: 'bar_chart' },
];

const MENUITEMSFORCLIENT = [
  { state: 'organization', name: 'Organization', type: 'link', icon: 'people' },
  { state: 'people', name: 'People', type: 'link', icon: 'person' },
  { state: 'groups', name: 'Groups', type: 'link', icon: 'group' },
  { state: 'fleets', name: 'Fleets', type: 'link', icon: 'account_balance' },
  { state: 'assets', name: 'Assets', type: 'link', icon: 'settings_remote' },
  { state: 'devices', name: 'Smartphones', type: 'link', icon: 'settings_remote' },
  { state: 'gps-live', name: 'IoT Logs', type: 'link', icon: 'gps_fixed' },
  { state: 'geofence', name: 'Geofences', type: 'link', icon: 'terrain' },
  { state: 'iot-settings', name: 'IoT Settings', type: 'link', icon: 'settings_applications' },
  { state: 'iot-report', name: 'IoT Reports', type: 'link', icon: 'gps_fixed' },
  { state: 'events', name: 'Events', type: 'link', icon: 'pages' },
  { state: 'analytics', name: 'Analytics', type: 'link', icon: 'bar_chart' },
  { state: 'logs', name: 'System Logs', type: 'link', icon: 'track_changes' },

];

const MENUITEMSFORADMIN = [

  { state: 'geofence', name: 'Geofence', type: 'link', icon: 'terrain' },
  { state: 'alert', name: 'Alerts', type: 'link', icon: 'warning' },
  { state: 'gps', name: 'IoT Reports', type: 'link', icon: 'gps_fixed' },
  { state: 'users', name: 'Users', type: 'link', icon: 'person' },
  { state: 'logs', name: 'Logs', type: 'link', icon: 'track_changes' },
];

@Injectable()

export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMSFORCLIENT;
  }

  getMenuitemForAdmin(): Menu[] {
    return MENUITEMSFORADMIN;
  }
}
