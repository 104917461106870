import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { ApiService } from '../api.service';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { SliderItems } from './slider.content';
import { NotifierService } from 'angular-notifier';
import { SocketService } from '../socket.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  /**
	 * Notifier service
	 */
  private notifier: NotifierService;

  data: any = {};
  loginForm = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(''),
  });
  index = 0;
  infinite = true;
  direction = 'right';
  directionToggle = true;
  autoplay = true;

  /**
	 * Constructor
   * @param {ApiService} api  Api service
   * @param {Router} router   Router
	 * @param {AuthService} auth Auth service
   * @param {SliderItems} sliderItems Slider Items
	 * @param {NotifierService} notifier Notifier service
	 */

  constructor(private api: ApiService, public router: Router, private auth: AuthService, public sliderItems: SliderItems,
    notifier: NotifierService, private readonly socket: SocketService) {
    this.notifier = notifier;
  }

  ngOnInit() {
    if (this.auth.isLoggedIn()) {
      this.router.navigate(['/people']);
    }
  }

  login() {

    // if(this.loginForm.value.email === 'beam@admin.com' && this.loginForm.value.password === '12345') {
    //   this.auth.setUser({id:"asdasdasdasd"});
    //   this.auth.setToken("astydasdasdsdadasd");
    //   this.notifier.notify('success', 'Login success');
    //   this.router.navigate(['assets']);
    // } else {
    //   this.notifier.notify('error', 'Invalid credentials');
    // }
    this.api.post('user/login', this.loginForm.value)
      .subscribe((res) => {
        const result = res.json();
        if (result && result.user) {
          this.auth.setToken(result.accessToken);
          this.auth.setUserRole(result.user.role);
          localStorage.setItem('user', JSON.stringify(result.user));
          this.socket.connect();
          this.notifier.notify('success', 'Login success');
          this.router.navigate(['/']);
        } else {
          this.notifier.notify('error', result.message);
        }
      }, err => {
        const res = err.json();
        this.notifier.notify('error', res.message);
      });
  }
  // getUserRole() {
  //   this.api.get('meteradmin/adminrole').subscribe((res) => {
  //     const result = res.json();
  //     if (result.meta_data.adminRole) {
  //       this.auth.setUserRole(result.meta_data.adminRole);
  //       if (result.meta_data.adminRole === 'Meter Admin') {
  //         this.router.navigate(['meter']);
  //       } else {
  //         this.router.navigate(['account']);
  //       }
  //     }
  //   });
  // }

}
