import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isLoggedIn()) {
      return true;
    } else {
      this.router.navigateByUrl('/login');
      return false;
    }
  }
}


@Injectable()
export class Redirect implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userRole = this.authService.getUserRole();
    if (userRole === 'admin') {
      this.router.navigateByUrl('/users');
      return false;
    } else {
      this.router.navigateByUrl('/people');
      return false;
    }
  }
}


@Injectable()
export class AdminGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userRole = this.authService.getUserRole();
    if (userRole === 'admin') {
      return true;
    } else {
      this.router.navigateByUrl('/people');
    }
  }
}



@Injectable()
export class ClientGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const userRole = this.authService.getUserRole();
    if (userRole === 'client') {
      return true;
    } else {
      this.router.navigateByUrl('/users');
    }
  }
}
