import { environment } from '../../environments/environment';
import { Socket } from 'ngx-socket-io';
import { Injectable } from '@angular/core';

@Injectable()
export class SocketIO extends Socket {
  constructor() {
    super({ url: environment.socketUrl, options: { query: `accessToken=${localStorage.getItem('jwt')}`, transports: ['websocket'] } });
  }
}
